import { ReactComponent as AppleIcon } from 'assets/apple.svg';
import { ReactComponent as CupIcon } from 'assets/cup.svg';
import { ReactComponent as DiningIcon } from 'assets/dining.svg';
import { ReactComponent as GymIcon } from 'assets/gym.svg';
import { ReactComponent as PiggyBankIcon } from 'assets/piggybank.svg';
import { SvgComponent } from 'types';

export interface WorkBenefitGridDataInterface {
  SvgComponent: SvgComponent;
  title: string;
  description: string;
}

export const workBenefitGridData: WorkBenefitGridDataInterface[] = [
  {
    SvgComponent: GymIcon,
    title: 'dofinansowania do karty Multisport',
    description: 'dofinansowanie do karty Multisport',
  },
  {
    SvgComponent: PiggyBankIcon,
    title: 'dofinansowanie dojazdów',
    description: 'dofinansowanie dojazdów',
  },
  {
    SvgComponent: CupIcon,
    title: 'napoje',
    description: 'ciepłe i zimne napoje w nielimitowanych ilościach',
  },
  {
    SvgComponent: DiningIcon,
    title: 'obiady',
    description: 'piątkowe obiady, na koszt firmy',
  },
  {
    SvgComponent: AppleIcon,
    title: 'owoce',
    description: 'słodycze i świeże owoce',
  },
];
