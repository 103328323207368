import { ourClientsGridData } from './ourClientsGridData';
import { OurClientsGridElement } from './OurClientsGridElement';

export const OurClientsGrid = () => (
  <div className="mx-auto flex max-w-[1440px]">
    <div className="flex w-full flex-wrap items-center justify-between md:gap-y-10">
      {ourClientsGridData.map((element) => (
        <OurClientsGridElement key={element.title} {...element} />
      ))}
    </div>
  </div>
);
