import { useEffect, useState } from 'react';

import { BREAKPOINTS } from '../../../../utils';

import { offerListData } from './offerListData';
import { OfferListElement } from './OfferListElement';

export const OfferList = () => {
  const [expandedId, setExpandedId] = useState(0);
  const [shouldElementsBeShrunk, setShouldElementsBeShrunk] = useState(
    window.innerWidth < BREAKPOINTS.md,
  );

  const mid = Math.ceil(offerListData.length / 2);
  const offerListDataColumns = [
    offerListData.slice(0, mid),
    offerListData.slice(mid),
  ];

  useEffect(() => {
    const getShouldElementsBeShrunk = () => {
      setShouldElementsBeShrunk(window.innerWidth < BREAKPOINTS.md);
    };

    window.addEventListener('resize', getShouldElementsBeShrunk);

    return () =>
      window.removeEventListener('resize', getShouldElementsBeShrunk);
  }, []);

  return (
    <div className="flex flex-col justify-between gap-12 md:flex-row xl:gap-24">
      {offerListDataColumns.map((offerListColumn, columnIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <ul className="flex flex-col gap-12" key={columnIndex}>
          {offerListColumn.map((element, index) => (
            <OfferListElement
              {...element}
              key={element.title}
              id={columnIndex === 0 ? index : index + mid}
              expandedId={expandedId}
              setExpandedId={setExpandedId}
              shouldElementsBeShrunk={shouldElementsBeShrunk}
            />
          ))}
        </ul>
      ))}
    </div>
  );
};
