import { BREAKPOINTS } from 'utils';

import { OurOfferGridElementInterface } from './OurOfferGridElement.interface';

export const OurOfferGridElement = ({
  SvgComponent,
  title,
  description,
}: OurOfferGridElementInterface) => (
  <div
    style={{
      width: window.innerWidth >= BREAKPOINTS.md ? 300 : window.innerWidth,
    }}
    className="flex shrink-0 justify-center md:h-[300px] md:w-[300px]"
  >
    <div className="flex w-3/4 max-w-[400px] flex-col justify-center gap-6 border-[3px] border-white md:w-full">
      <div className="flex justify-center">
        <SvgComponent title={title} />
      </div>

      <h3 className="text-center text-2xl font-semibold uppercase text-white md:text-xl">
        {title}
      </h3>

      <div className="text-center text-xl text-white xs:text-2xl md:hidden">
        {description}
      </div>
    </div>
  </div>
);
