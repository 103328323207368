import { ReactComponent as Arrow } from 'assets/arrow.svg';
import hero from 'assets/hero.jpg';

import { OurOfferGrid, OurClientsGrid } from './components';

export const Home = () => (
  <div>
    <div
      style={{ backgroundImage: `url(${hero})` }}
      className="h-[650px] bg-[75%_bottom] md:h-[850px] md:bg-[60%_0]"
    />

    <div className="relative hidden md:block">
      <Arrow className="absolute right-0 top-[-20vw] z-10 w-[25vw] max-w-[480px] rotate-90 fill-white/70 fhd:top-[-384px]" />
    </div>

    <div className="mx-[12.5%] mt-[-210px] flex h-auto items-center bg-white sm:h-[530px] md:absolute md:top-[330px] md:m-0 md:h-[495px] md:w-[60vw] md:max-w-[1150px] md:bg-white/90">
      <div className="absolute top-0 hidden md:block">
        <Arrow className="absolute bottom-0 z-10 w-[16vw] max-w-[307px] fill-primary md:top-[-6vw] fhd:top-[-115px]" />
      </div>
      <div className="relative p-4 pt-8 xs:p-8 sm:p-16 md:ml-[16vw] md:px-8 md:pt-16 lg:p-16 fhd:ml-[307px]">
        <h1 className="text-center text-2xl font-bold sm:text-4xl md:text-left lg:text-5xl xl:text-6xl">
          Poznaj Logicas
        </h1>
        <div className="mt-12 text-xl font-medium leading-normal sm:text-2xl md:text-xl xl:text-2xl">
          <p className="text-center font-bold text-primary md:text-left">
            Logicas to młoda, dynamicznie rozwijająca się firma stworzona przez
            pasjonatów logistyki oraz e-commerce.
          </p>
          <p className="ml-0 mt-8 text-center font-semibold text-[#727272] xs:ml-[12vw] xs:text-left sm:ml-[8vw] md:ml-0 md:text-black">
            Jesteśmy ekspertami usług logistycznych dla firm działających w
            szeroko pojętej sprzedaży wysyłkowej.
          </p>
        </div>
      </div>
    </div>

    <div className="relative">
      <Arrow className="absolute top-[-26vw] z-10 hidden w-[26vw] max-w-[284px] -rotate-90 fill-secondary xs:block md:top-[-220px] md:w-[14.8vw] md:fill-black" />
    </div>

    <div className="bg-primary pb-16 md:pb-24">
      <h2 className="py-12 text-center text-4xl font-bold text-white md:py-24 md:text-5xl">
        Nasza oferta
      </h2>
      <OurOfferGrid />
    </div>

    <div className="px-4 sm:px-16 md:px-24">
      <h2 className="py-24 text-center text-4xl font-bold md:text-5xl">
        Nasi klienci
      </h2>
      <OurClientsGrid />
    </div>
  </div>
);
