import { BenefitListElementInterface } from './BenefitListElement.interface';

export const BenefitListElement = ({
  title,
  content,
}: BenefitListElementInterface) => (
  <li className="text-white">
    <h2 className="text-3xl">{title}</h2>
    <p className="pt-5 text-xl">{content}</p>
  </li>
);
