import { ReactNode } from 'react';

import { ReactComponent as CoPackingIcon } from 'assets/copacking.svg';
import { ReactComponent as ExportIcon } from 'assets/export.svg';
import { ReactComponent as FulfillmentIcon } from 'assets/fulfillment.svg';
import { ReactComponent as GlobeIcon } from 'assets/globe.svg';
import { ReactComponent as PackageIcon } from 'assets/package.svg';
import { ReactComponent as ReturnsIcon } from 'assets/returns.svg';
import { ReactComponent as StorageIcon } from 'assets/storage.svg';
import { ReactComponent as TransportIcon } from 'assets/transport.svg';
import { SvgComponent } from 'types';

export interface OurOfferGridDataInterface {
  SvgComponent: SvgComponent;
  title: string;
  description: ReactNode;
}

export const ourOfferGridData: OurOfferGridDataInterface[] = [
  {
    SvgComponent: FulfillmentIcon,
    title: 'fulfilment',
    description:
      'obsługa sklepów internetowych jeżeli chodzi o pełne zarządzanie procesami logistycznymi end-to-end',
  },
  {
    SvgComponent: PackageIcon,
    title: 'zarządzanie materiałami opakowaniowymi',
    description: 'dla klientów stworzymy dedykowany, piękny sposób pakowania',
  },
  {
    SvgComponent: StorageIcon,
    title: 'magazynowanie',
    description: (
      <>
        Nowoczesny magazyn klasy A, w doskonałej lokalizacji, blisko kluczowych
        węzłów komunikacyjnych
        <ul className="list-disc pl-10">
          <li>8km od Lotniska Ławica</li>
          <li>180km od Wrocławia</li>
          <li>330km od Warszawy</li>
          <li>270km od Berlina</li>
        </ul>
      </>
    ),
  },
  {
    SvgComponent: TransportIcon,
    title: 'transport lądowy, lotniczy, kontenerowy',
    description:
      'szerokie doświadczenie oraz możliwość korzystania z preferencyjnych stawek - uzyskuje się efekt skali innych klientów Logicas',
  },
  {
    SvgComponent: ExportIcon,
    title: 'eksport',
    description:
      'wsparcie formalne przesyłek poza EU, w tym eksportu do Wielkiej Brytanii',
  },
  {
    SvgComponent: ReturnsIcon,
    title: 'obsługa zwrotów oraz reklamacji',
    description: 'pełna obsługa zwrotów oraz reklamacji produktów',
  },
  {
    SvgComponent: CoPackingIcon,
    title: 'co-packing',
    description:
      'tworzenie zestawów sprzedażowych lub dedykowanych na konkretne okazje np. paczek świątecznych',
  },
  {
    SvgComponent: GlobeIcon,
    title: 'możliwość obsługi rynku brytyjskiego',
    description:
      'możliwość obsługi rynku brytyjskiego za pośrednictwem naszego lokalnego magazynu. Jako jedna z nielicznych firm jesteśmy w stanie zapewnić obsługę przesyłek zarówno w Europie jak i Wielkiej Brytanii',
  },
];
