import { ReactComponent as Arrow } from 'assets/arrow.svg';
import forklift from 'assets/forklift.jpg';
import warehousePoznan from 'assets/warehouse-poznan.jpg';
import warehouseWorker02 from 'assets/warehouse-worker02.jpg';

import { BenefitList, OfferList } from './components';

export const Offer = () => (
  <div className="mb-36 md:mb-0">
    <div className="absolute lg:static">
      <div className="relative top-12 lg:static lg:top-0 lg:mt-12 lg:flex lg:flex-row-reverse lg:justify-end">
        <h1 className="bg-white px-6 py-10 text-4xl font-bold leading-tight sm:px-12 sm:py-16 sm:text-5xl lg:flex lg:items-center lg:bg-transparent lg:p-0 lg:pl-8 lg:text-6xl">
          Nasza oferta
        </h1>
        <div className="relative">
          <Arrow className="absolute -top-10 z-10 w-[130px] -rotate-90 fill-primary sm:w-[173px] lg:static lg:rotate-0" />
        </div>
      </div>
    </div>

    <div
      className="h-[400px] w-full bg-[30%_80%] bg-no-repeat lg:hidden"
      style={{ backgroundImage: `url(${forklift})` }}
    />

    <div className="mx-10 mb-24 mt-10 flex w-2/3 flex-col gap-10 md:w-auto md:justify-end lg:mr-0 lg:flex-row xl:gap-24">
      <OfferList />
      <div className="hidden w-full max-w-[650px] lg:block">
        <div className="relative 2xl:mt-[-150px]">
          <div
            className="h-[600px] bg-cover bg-[50%_0]"
            style={{ backgroundImage: `url(${forklift})` }}
          />
          <Arrow className="absolute right-0 top-0 z-10 w-[12vw] max-w-[222px] fill-white/70" />
        </div>
        <div className="relative mt-16">
          <div
            className="h-[600px] bg-cover bg-[0_50%]"
            style={{ backgroundImage: `url(${warehouseWorker02})` }}
          />
          <Arrow className="absolute bottom-[-30px] right-0 z-10 w-[12vw] max-w-[222px] rotate-180 fill-secondary" />
        </div>
      </div>
    </div>

    <div
      className="bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${warehousePoznan})`,
      }}
    >
      <div className="bg-primary/90 pb-56 pt-16 md:pb-8">
        <div className="flex items-center md:mx-auto md:block md:text-center">
          <h1 className="ml-10 text-4xl font-bold leading-tight text-white sm:ml-16 sm:text-5xl md:ml-0 md:text-7xl">
            Twoje korzyści
          </h1>
          <div className="ml-auto md:hidden">
            <Arrow className="mr-12 w-[18vw] max-w-[143px] -rotate-90 fill-white" />
          </div>
        </div>
        <BenefitList />
      </div>
    </div>

    <div className="relative md:hidden">
      <Arrow className="absolute right-0 top-[-11vw] z-10 w-[33vw] rotate-180 fill-secondary" />
    </div>
  </div>
);
