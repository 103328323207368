export interface NavigationDataInterface {
  name: string;
  route: string;
}

export const navigationData: NavigationDataInterface[] = [
  { name: 'O nas', route: '/o-nas' },
  { name: 'Oferta', route: '/oferta' },
  { name: 'Praca', route: '/praca' },
  { name: 'Kontakt', route: '/kontakt' },
];
