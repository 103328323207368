import { Link } from 'react-router-dom';

import { ReactComponent as Arrow } from 'assets/arrow.svg';
import warehouseWorker01 from 'assets/warehouse-worker01.jpg';
import warehouseWorker20 from 'assets/warehouse-worker20.jpg';

import { WorkBenefitGrid } from './components';

export const Work = () => (
  <div>
    <div className="relative">
      <div className="absolute bottom-0 right-0 ml-auto h-fit w-1/2 md:inset-0 md:my-auto">
        <div className="bg-white/90 px-2 py-4 font-semibold sm:px-6 md:p-12 xl:p-24">
          <h1 className="font-bold sm:pb-8 sm:text-3xl md:text-4xl lg:text-6xl">
            Praca w Logicas
          </h1>
          <p className="text-sm sm:text-xl md:text-2xl">
            <span className="mb-6 block text-primary">
              Od 2 lat rozwijamy się w sektorze logistycznym, tworząc miejsce
              pracy dla ponad 30 pracowników tylko w naszym polskim magazynie
            </span>
            Dbamy o nasz team, bo wiemy że dobra atmosfera to podstawa każdego
            miejsca pracy
          </p>
        </div>
      </div>
      <div
        className="h-[360px] w-full bg-cover bg-[0_30%] xs:h-[400px] sm:h-[480px] md:h-[780px]"
        style={{
          backgroundImage: `url(${warehouseWorker01})`,
        }}
      />
    </div>

    <div className="mx-4 mt-16 border border-primary md:mx-10 lg:mx-16">
      <div className="p-4 text-xl font-semibold sm:p-8 sm:text-2xl">
        <p>
          Umożliwiamy także rozwój w obrębie naszej firmy poprzez awans zawodowy
          czy też szkolenia.
        </p>
        <p className="mt-4">
          Dla naszych pracowników przygotowaliśmy również szeroki pakiet
          benefitów, który umili czas w pracy i czas wolny po niej.{' '}
          <span className="md:mt-10 md:block md:text-primary">
            Między innymi:
          </span>
        </p>
      </div>
      <div className="my-5 flex">
        <WorkBenefitGrid />
        <div className="hidden md:block md:w-1/2">
          <div className="relative">
            <Arrow className="absolute -right-10 -top-12 z-10 w-[14vw] max-w-[273px] rotate-90 fill-secondary lg:-right-16 lg:-top-24" />
          </div>
          <img className="ml-auto" src={warehouseWorker20} alt="pracownik" />
        </div>
      </div>
    </div>

    <img className="my-12 md:hidden" src={warehouseWorker20} alt="pracownik" />

    <div className="mb-24 flex items-center lg:w-4/5">
      <div>
        <Arrow className="w-[32vw] max-w-[244px] fill-primary md:w-[18vw] md:max-w-[339px]" />
      </div>
      <h1 className="px-10 text-xl font-bold leading-tight sm:text-3xl md:px-16 lg:text-4xl xl:text-5xl">
        Zaaplikuj do Logicas jeśli chcesz razem z nami pracować na własny sukces
        i naszej firmy!
      </h1>
    </div>

    <div className="flex flex-col items-center px-10 md:items-start md:px-16 xl:px-64">
      <h2 className="text-center text-2xl md:text-3xl">
        Obecnie poszukujemy na stanowisko:
      </h2>
      <div className="mb-8 mt-10 w-full border-t border-t-black" />
      <div className="flex flex-col items-center md:w-full md:flex-row md:pl-8">
        <p className="mb-8 text-3xl font-semibold md:mb-0">Magazynier</p>
        <Link
          to="/zobacz-oferte"
          className="text-xl text-primary underline md:ml-16"
        >
          Zobacz ofertę
        </Link>
        <div className="mt-12 md:ml-auto md:mt-0">
          <Link
            to="https://pracodawcy.pracuj.pl/company/1074061557?_ga=2.66804398.1233719096.1691134636-1440246517.1691134636"
            className="rounded-full bg-primary px-16 py-2 text-xl font-bold text-white hover:opacity-80"
          >
            Aplikuj
          </Link>
        </div>
      </div>
      <div className="mt-5 w-full border-t border-t-black md:mt-8" />
    </div>
  </div>
);
