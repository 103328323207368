import { ReactNode } from 'react';

export interface OfferListDataInterface {
  title: string;
  content: ReactNode;
}

export const offerListData: OfferListDataInterface[] = [
  {
    title: 'Fulfilment',
    content: (
      <p>
        Obsługa sklepów internetowych jeżeli chodzi o pełne zarządzanie
        procesami logistycznymi end-to-end
      </p>
    ),
  },
  {
    title: 'Magazynowanie',
    content: (
      <div>
        <p>
          Nowoczesny magazyn klasy A w doskonałej lokalizacji, blisko kluczowych
          węzłów komunikacyjnych
        </p>
        <ul className="list-disc pl-10">
          <li>8km od Lotniska Ławica</li>
          <li>180km od Wrocławia</li>
          <li>330km od Warszawy</li>
          <li>270km od Berlina</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Eksport',
    content: (
      <p>
        Wsparcie formalne eksportu przesyłek poza EU, w tym eksportu do Wielkiej
        Brytanii
      </p>
    ),
  },
  {
    title: 'Transport lądowy, lotniczy, kontenerowy',
    content: (
      <p>
        doświadczenie oraz możliwość korzystania z preferencyjnych stawek -
        uzyskuje się efekt skali innych klientów Logicas
      </p>
    ),
  },
  {
    title: 'Zarządzanie materiałami opakowaniowymi',
    content: <p>Dla klientów stworzymy dedykowany, piękny sposób pakowania</p>,
  },
  {
    title: 'Obsługa zwrotów oraz reklamacji',
    content: <p>Pełna obsługa zwrotów i reklamacji</p>,
  },
  {
    title: 'Co-packing',
    content: (
      <p>
        Tworzenie zestawów sprzedażowych lub dedykowanych na konkretne okazje
        np. paczek świątecznych
      </p>
    ),
  },
  {
    title: 'Możliwość obsługi rynku brytyjskiego',
    content: (
      <p>
        Za pośrednictwem naszego lokalnego magazynu. Jako jedna z nielicznych
        firm jesteśmy w stanie zapewnić obsługę przesyłek zarówno w Europie jak
        i Wielkiej Brytanii
      </p>
    ),
  },
];
