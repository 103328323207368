import { OurClientsGridElementInterface } from './OurClientsGridElement.interface';

export const OurClientsGridElement = ({
  SvgComponent,
  title,
}: OurClientsGridElementInterface) => (
  <SvgComponent
    className="shrink-0 grow-0 basis-1/2 px-2 lg:basis-1/4"
    title={title}
  />
);
