import { Fragment } from 'react';

import { ReactComponent as Circle } from 'assets/circle.svg';

import { benefitListData } from './benefitListData';
import { BenefitListElement } from './BenefitListElement';

const Separator = () => (
  <li aria-hidden className="md:hidden">
    <div className="relative h-[15px] w-[15px] rounded-lg bg-white">
      <div className="absolute left-1/2 top-1/2 h-[3px] w-[175px] -translate-y-1/2 bg-white" />
    </div>
  </li>
);

const benefitElements = benefitListData.map((element, index) => (
  <Fragment key={element.title}>
    <BenefitListElement {...element} />
    {index !== benefitListData.length - 1 ? <Separator /> : null}
  </Fragment>
));

export const BenefitList = () => (
  <div className="mx-10 mt-10 flex flex-col gap-10 sm:mx-24 md:mx-2 md:flex-row md:justify-center lg:mt-10">
    <ul className="flex grow basis-0 flex-col gap-10 md:max-w-[350px] lg:mt-20">
      {benefitElements[0]}
      {benefitElements[1]}
    </ul>

    <ul className="flex grow basis-0 flex-col gap-10 md:mt-16 md:max-w-[400px] md:items-center md:text-center lg:mt-0 lg:grow-[1.5]">
      <Circle className="hidden md:block" />
      {benefitElements[2]}
    </ul>

    <ul className="flex grow basis-0 flex-col gap-10 md:max-w-[350px] lg:mt-20">
      {benefitElements[3]}
      {benefitElements[4]}
    </ul>
  </div>
);
