import { navigationData } from '../utils';

import { FooterNavigationElement } from './FooterNavigationElement';

export const FooterNavigation = () => (
  <nav className="flex justify-center">
    <ul className="flex flex-col justify-center whitespace-nowrap text-xl font-semibold xs:flex-row xs:gap-6 lg:gap-12 xl:gap-24 xl:text-2xl">
      {navigationData.map((element) => (
        <FooterNavigationElement {...element} key={element.name} />
      ))}
    </ul>
  </nav>
);
