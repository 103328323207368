import { ReactComponent as Arrow } from 'assets/arrow.svg';
import { ReactComponent as Map } from 'assets/map.svg';
import warehouseWorkers from 'assets/warehouse-workers.jpg';

import { Warehouse } from './components';
import { warehouseData } from './components/Warehouse/warehouseData';

export const About = () => (
  <div>
    <div className="mb-10 lg:mb-24 lg:flex 2xl:items-center">
      <div className="lg:w-1/2">
        <div className="flex">
          <div>
            <Arrow className="w-[25vw] fill-primary md:w-[193px]" />
          </div>
          <h1 className="flex items-center pl-8 text-2xl font-bold leading-tight xs:text-4xl md:pl-16 md:text-5xl lg:text-4xl 2xl:text-6xl">
            Dbamy o każdy aspekt procesów logistycznych
          </h1>
        </div>
        <p className="px-10 pt-10 text-xl font-semibold md:text-2xl lg:mb-40 xl:pl-48">
          potrzebnych do sprawnego funkcjonowania sklepów internetowych naszych
          partnerów. Do grona klientów z którymi współpracujemy, możemy zaliczyć
          zarówno duże spółki z kapitałem zagranicznym obejmujące swoim
          zasięgiem obszar całej Europy, jak i mniejsze lokalnie działające
          sklepy internetowe. Naszym priorytetem jest świadczenie usług na
          najwyższym poziomie, tak aby spełnić jak najlepiej potrzeby naszych
          klientów.
        </p>
      </div>
      <div className="px-10 pt-10 lg:w-1/2">
        <Map />
      </div>
    </div>

    <div className="bg-[#d9d9d9]/20 pt-24 lg:flex lg:gap-10 lg:px-10">
      <div className="lg:-mt-72 lg:w-1/2">
        <Warehouse {...warehouseData[0]} />
      </div>
      <div className="lg:w-1/2">
        <Warehouse {...warehouseData[1]} />
      </div>
    </div>

    <div className="md:flex">
      <div className="flex items-center bg-primary p-10 font-semibold text-white xs:px-20 sm:py-16 md:w-1/2 md:px-10 lg:px-20 lg:py-8 xl:px-40">
        <div>
          <h2 className="pb-10 text-5xl font-bold">Dobre relacje</h2>
          <p className="sm:text-2xl md:text-xl lg:text-2xl">
            Przykładamy również wagę do tego, aby nasi pracownicy byli
            zadowoleni z wyboru miejsca pracy, dbając codziennie o dobrą
            atmosferę. Relacje opieramy na wzajemnym szacunku i przyjaźni,
            umożliwiając jednocześnie rozwój, tak aby nam wszystkim pracowało
            się jak najlepiej.
          </p>
        </div>
      </div>
      <div
        className="h-[550px] bg-cover bg-[20%_0] bg-no-repeat md:w-1/2"
        style={{ backgroundImage: `url(${warehouseWorkers})` }}
      />
    </div>
  </div>
);
