import { ReactNode } from 'react';

export interface BenefitListDataInterface {
  title: string;
  content: ReactNode;
}

export const benefitListData: BenefitListDataInterface[] = [
  {
    title: 'Szybkie wdrożenie',
    content:
      'Możesz liczyć na nasze wsparcie przy wdrożeniu wspólnego procesu logistycznego',
  },
  {
    title: 'Nieograniczone możliwości',
    content: 'Możliwość praktycznie nieograniczonego wzrostu skali sprzedaży',
  },
  {
    title: 'Przejmujemy stery',
    content:
      'My zapewniamy powierzchnię, transport i wyszkolony team, dzięki czemu możesz się skupić na innych segmentach rozwoju twojego biznesu',
  },
  {
    title: 'Zawsze na czas',
    content:
      'Sprawnie obsługujemy szczyty sprzedażowe, ponieważ ustalamy dobry harmonogram pracy na najgorętsze okresy roku',
  },
  {
    title: 'Pogląd na wszystko',
    content:
      'Dzięki integracji z nowoczesnym systemem magazynowym możesz śledzić produkt po numerze LOT, a także filtrować towar po dacie ważności',
  },
];
