import { ReactComponent as Arrow } from 'assets/arrow.svg';
import forklift2 from 'assets/forklift2.jpg';

import map from '../../assets/map.jpg';

export const Contact = () => (
  <div>
    <div className="my-20 xl:flex">
      <div className="flex items-center xl:w-1/2">
        <div>
          <Arrow className="w-[25vw] max-w-[193px] fill-primary" />
        </div>
        <h1 className="pl-8 text-2xl font-bold leading-tight sm:text-4xl lg:text-5xl xl:text-4xl 2xl:text-5xl">
          Opowiedz nam o swoich planach, a my Ci pokażemy drogę aby je osiągnąć
        </h1>
      </div>
    </div>

    <div
      className="bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${forklift2})` }}
    >
      <div className="bg-primary/90 pb-10 lg:pb-48">
        <h2 className="py-16 text-center text-3xl font-bold text-white md:py-24">
          Skontaktuj się z nami
        </h2>

        <div className="mx-5 bg-white sm:mx-12 lg:mx-20 xl:mx-40">
          <div className="mx-5 flex flex-col gap-10 py-20 sm:mx-20 lg:mx-12 lg:flex-row lg:gap-24 xl:mx-24">
            <div className="flex items-center justify-center text-center text-2xl lg:w-[45%] lg:py-0 lg:text-left xl:text-3xl">
              <p>
                <span className="font-bold">Logicas</span>
                <br />
                biuro@logicas.pl
                <br />
                +48 799 289 335
                <br />
                <span className="mt-6 inline-block">św. Mikołaja 7,</span>
                <br />
                62-080 Swadzim,
                <br />
                Polska
              </p>
            </div>
            <img className="lg:w-[55%]" src={map} alt="lokalizacja" />
          </div>
        </div>
      </div>
    </div>
  </div>
);
