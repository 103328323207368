import { ReactComponent as OlivitLogo } from 'assets/olivit.svg';
import { ReactComponent as PolgarLogo } from 'assets/polgar.svg';
import { ReactComponent as PowerbodyLogo } from 'assets/powerbody.svg';
import { ReactComponent as ShamanLogo } from 'assets/shaman.svg';
import { SvgComponent } from 'types';

export interface OurClientsGridDataInterface {
  SvgComponent: SvgComponent;
  title: string;
}

export const ourClientsGridData: OurClientsGridDataInterface[] = [
  {
    SvgComponent: OlivitLogo,
    title: 'Olivit',
  },
  {
    SvgComponent: PolgarLogo,
    title: 'Polgar',
  },
  {
    SvgComponent: PowerbodyLogo,
    title: 'Powerbody',
  },
  {
    SvgComponent: ShamanLogo,
    title: 'Shaman',
  },
];
