import { WorkBenefitGridElementInterface } from './WorkBenefitGridElement.interface';

export const WorkBenefitGridElement = ({
  SvgComponent,
  title,
  description,
}: WorkBenefitGridElementInterface) => (
  <div className="flex w-1/2 flex-col px-2 md:w-1/3">
    <div className="flex flex-auto items-center justify-center">
      <SvgComponent
        title={title}
        className="scale-100 md:scale-75 lg:scale-100"
      />
    </div>
    <div className="flex h-24 justify-center px-2 pt-2 text-center md:h-36">
      <p className="font-semibold text-primary sm:text-xl md:text-sm lg:text-base xl:text-xl 2xl:text-2xl">
        {description}
      </p>
    </div>
  </div>
);
