import { ReactComponent as GridArrow } from 'assets/grid-arrow.svg';

import { OurOfferGridButtonInterface } from './OurOfferGridButton.interface';

export const OurOfferGridButton = ({
  disabled,
  onClick,
  isLeft = false,
}: OurOfferGridButtonInterface) => (
  <button
    style={{
      width: Math.max((window.innerWidth - 400) / 2, window.innerWidth / 8),
    }}
    className={`${
      isLeft ? 'left-0' : 'right-0'
    } absolute z-10 flex h-full items-center justify-center md:hidden`}
    type="button"
    disabled={disabled}
    onClick={onClick}
  >
    <GridArrow
      className={`${disabled ? 'hidden' : ''} ${isLeft ? 'rotate-180' : ''}`}
    />
  </button>
);
