import { NavLink } from 'react-router-dom';

import { FooterNavigationElementInterface } from './FooterNavigationElement.interface';

export const FooterNavigationElement = ({
  name,
  route,
}: FooterNavigationElementInterface) => (
  <li>
    <NavLink
      className="border-l-2 border-primary pl-6 hover:underline md:pl-2"
      to={route}
    >
      {name}
    </NavLink>
  </li>
);
