import { ReactNode, useEffect } from 'react';
import { useLocation, useRoutes, Navigate } from 'react-router-dom';

import { About, Contact, Home, Offer, Work, WorkOffer } from 'pages';

import { Footer, Header } from './components';

const WithHeaderAndFooter = ({ children }: { children: ReactNode }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const App = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <Home />,
    },
    {
      path: 'o-nas',
      element: <About />,
    },
    {
      path: 'oferta',
      element: <Offer />,
    },
    {
      path: 'praca',
      element: <Work />,
    },
    {
      path: 'kontakt',
      element: <Contact />,
    },
    {
      path: 'zobacz-oferte',
      element: <WorkOffer />,
    },
    { path: '*', element: <Navigate to="/" /> },
  ]);

  return (
    <WithHeaderAndFooter>
      <ScrollToTop />
      {routes}
    </WithHeaderAndFooter>
  );
};
