import { NavLink } from 'react-router-dom';

import { HeaderNavigationElementInterface } from './HeaderNavigationElement.interface';

export const HeaderNavigationElement = ({
  name,
  route,
  isMobileNavigation,
  onClick,
}: HeaderNavigationElementInterface) => (
  <li>
    <NavLink
      className={({ isActive }) => {
        const activeLinkStyles = isActive ? 'text-primary' : '';
        const mobileNavigationStyles = isMobileNavigation ? 'text-3xl' : '';
        return `${activeLinkStyles} ${mobileNavigationStyles} hover:underline`;
      }}
      to={route}
      onClick={onClick}
    >
      {name}
    </NavLink>
  </li>
);
