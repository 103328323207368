import { useEffect, useRef, useState } from 'react';

import { ReactComponent as ListArrow } from 'assets/list-arrow.svg';

import { OfferListElementInterface } from './OfferListElement.interface';

export const OfferListElement = ({
  id,
  title,
  content,
  expandedId,
  setExpandedId,
  shouldElementsBeShrunk,
}: OfferListElementInterface) => {
  const [isTransition, setIsTransition] = useState(false);
  const [maxHeight, setMaxHeight] = useState<number>();
  const [contentHeight, setContentHeight] = useState<number>();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setContentHeight(contentRef.current?.offsetHeight);
    });
    resizeObserver.observe(contentRef.current);
    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    setIsTransition(true);

    const setIsTransitionTimeout = setTimeout(() => {
      setIsTransition(false);
    }, 300);

    return () => clearTimeout(setIsTransitionTimeout);
  }, [id, expandedId]);

  useEffect(() => {
    if (shouldElementsBeShrunk) {
      if (expandedId === id) {
        setMaxHeight(contentHeight);
      } else {
        setMaxHeight(0);
      }
    } else {
      setMaxHeight(undefined);
    }
  }, [id, expandedId, shouldElementsBeShrunk, contentHeight]);

  return (
    <li className="max-w-[415px] md:w-full">
      {shouldElementsBeShrunk ? (
        <button
          type="button"
          className="flex w-full items-center gap-6"
          onClick={() => {
            setExpandedId(id);
          }}
        >
          <span className="text-left text-xl font-semibold uppercase text-[#727272] xs:text-2xl">
            {title}
          </span>
          <ListArrow
            className={`shrink-0 ${expandedId === id ? 'rotate-180' : ''}`}
          />
        </button>
      ) : (
        <h2 className="pl-10 text-xl font-semibold uppercase text-[#727272] xs:text-2xl">
          {title}
        </h2>
      )}

      <div
        style={{
          transitionDuration: isTransition ? '300ms' : '0s',
          maxHeight,
        }}
        className="mt-8 overflow-hidden transition-[max-height] md:mt-8 md:max-h-full md:overflow-auto md:transition-none"
      >
        <div
          className="border border-primary px-8 py-5 text-xl text-primary md:px-8 md:py-10"
          ref={contentRef}
        >
          {content}
        </div>
      </div>
    </li>
  );
};
