import { ReactComponent as Checkmark } from 'assets/checkmark.svg';

import { WorkOfferListElementInterface } from './WorkOfferListElement.interface';

export const WorkOfferListElement = ({
  element,
}: WorkOfferListElementInterface) => (
  <li className="mt-4 flex">
    <Checkmark className="mr-2 mt-1 inline shrink-0 text-primary" />
    <p>{element}</p>
  </li>
);
