import { workBenefitGridData } from './workBenefitGridData';
import { WorkBenefitGridElement } from './WorkBenefitGridElement';

export const WorkBenefitGrid = () => (
  <div className="flex flex-wrap justify-center md:w-1/2 lg:w-3/5 lg:px-10">
    {workBenefitGridData.map((element) => (
      <WorkBenefitGridElement {...element} key={element.title} />
    ))}
  </div>
);
