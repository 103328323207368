import { ReactComponent as Logo } from 'assets/logo.svg';

import { FooterNavigation } from '../Navigation';

export const Footer = () => (
  <footer
    className="px-16
       pb-12 pt-24 md:px-24 md:pb-32 md:pt-24"
  >
    <div className="border-t border-t-black/20 px-16" />

    <div className="mt-16 md:mt-24 md:flex lg:px-16 xl:px-24">
      <div className="flex flex-col items-center md:items-start">
        <Logo className="w-[210px]" />
        <div className="mt-8 text-center text-2xl text-primary md:mt-20 md:text-left">
          <p>Logicas</p>
          <p>św. Mikołaja 7, 62‑080 Swadzim, Polska</p>
        </div>
      </div>
      <div className="ml-auto mt-12 flex-col md:mt-0">
        <FooterNavigation />
        <div className="mt-20 text-center text-xl text-black/20 md:text-right md:text-2xl">
          ©2022 Logicas
        </div>
      </div>
    </div>
  </footer>
);
