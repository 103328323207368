import { ReactNode } from 'react';

import warehousePoznan from 'assets/warehouse-poznan.jpg';
import warehouseUK from 'assets/warehouse-uk.jpg';

export interface WarehouseDataInterface {
  imgSrc: string;
  imgAlt: string;
  paragraphOneContent: ReactNode;
  surfaceArea: number;
  paragraphTwoContent: ReactNode;
}

export const warehouseData: WarehouseDataInterface[] = [
  {
    imgSrc: warehousePoznan,
    imgAlt: 'magazyn w Poznaniu',
    paragraphOneContent: (
      <>
        Pierwszy magazyn Logicas mieści się tuż obok Poznania, w doskonale
        skomunikowanej gminie - Tarnowo Podgórne. W najbliższym sąsiedztwie
        znajduje się trasa S11 zapewniająca szybki dojazd do sortowni
        kurierskich oraz Port Lotniczy Poznań - Ławica.
      </>
    ),
    surfaceArea: 4500,
    paragraphTwoContent: (
      <>
        Naszą przestrzeń logistyczną liczącą blisko 4500 m<sup>2</sup>,
        wyposażyliśmy w regały wysokiego składowania, z miejscem na 30 tysięcy
        lokalizacji produktów naszych partnerów biznesowych
      </>
    ),
  },
  {
    imgSrc: warehouseUK,
    imgAlt: 'magazyn w Wielkiej Brytanii',
    paragraphOneContent: (
      <>
        Drugi magazyn jakim dysponujemy znajduje się na północy Anglii, w
        okolicach miasta York. Dzięki tej lokalizacji zapewniamy także dostęp do
        niezwykle chłonnego rynku brytyjskiego.
      </>
    ),
    surfaceArea: 3000,
    paragraphTwoContent: (
      <>
        Nasza przestrzeń logistyczna zajmuje tutaj około 3000 m<sup>2</sup> oraz
        prawie 10 tysięcy lokalizacji magazynowych.
      </>
    ),
  },
];
