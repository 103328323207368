import { WarehouseInterface } from './Warehouse.interface';

export const Warehouse = ({
  imgSrc,
  imgAlt,
  paragraphOneContent,
  surfaceArea,
  paragraphTwoContent,
}: WarehouseInterface) => (
  <div className="mx-auto max-w-[550px] px-10 pb-12 font-semibold text-primary sm:px-0">
    <img
      src={imgSrc}
      alt={imgAlt}
      className="max-h-[450px] w-full object-cover"
    />
    <p className="pt-8 text-xl md:text-2xl">{paragraphOneContent}</p>
    <p className="pt-8 text-2xl md:text-4xl">
      {surfaceArea} m<sup>2</sup>
    </p>
    <p className="pt-8 text-xl md:text-2xl">{paragraphTwoContent}</p>
  </div>
);
