import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/logo.svg';

import { HeaderNavigation } from '../Navigation';

export const Header = () => (
  <header className="flex h-[160px]">
    <div className="my-auto flex w-full items-center justify-between px-6 xs:px-12 xl:pr-24">
      <Link className="z-50" to="/">
        <Logo className="w-[180px] sm:w-[226px] md:w-[304px]" />
      </Link>
      <HeaderNavigation />
    </div>
  </header>
);
