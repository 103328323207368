import { Link } from 'react-router-dom';

import { ReactComponent as Arrow } from 'assets/arrow.svg';
import warehouseWorker10 from 'assets/warehouse-worker10.jpg';

import { WorkOfferList } from './components';
import {
  workOfferDescription,
  workOfferDetails,
  workOfferRequirements,
} from './workOfferListData';

export const WorkOffer = () => (
  <div className="mb-10">
    <div className="bg-primary/10 py-10 pl-5 sm:pl-24 lg:w-1/2">
      <h1 className="text-3xl font-semibold">Pracownik magazynu</h1>
      <h2 className="mt-4 text-2xl">Miejsce pracy: Swadzim</h2>
    </div>

    <div className="mt-4 lg:w-1/2">
      <WorkOfferList
        title="Opis stanowiska:"
        workOfferListData={workOfferDescription}
        elementTextColor="primary"
      />
    </div>

    <div className="relative mt-24">
      <div className="right-0 lg:absolute lg:top-[-500px] lg:w-1/2 xl:top-[-450px]">
        <img className="ml-auto xs:w-3/4" src={warehouseWorker10} alt="" />
        <Arrow className="absolute -left-10 -top-8 -z-10 hidden w-[40vw] max-w-[326px] -rotate-90 fill-black xs:block lg:-top-16 lg:left-10 lg:w-[17vw]" />
        <Arrow className="absolute right-0 hidden w-[27vw] max-w-[515px] rotate-180 fill-primary lg:block" />
      </div>
    </div>

    <div className="mt-24 lg:w-1/2">
      <WorkOfferList
        title="Wymagania:"
        workOfferListData={workOfferRequirements}
      />
    </div>

    <div className="mt-16 lg:w-1/2">
      <WorkOfferList title="Oferujemy:" workOfferListData={workOfferDetails} />
    </div>

    <div className="mt-20 flex justify-center lg:w-1/2 lg:justify-end">
      <Link
        to="https://pracodawcy.pracuj.pl/company/1074061557?_ga=2.66804398.1233719096.1691134636-1440246517.1691134636"
        className="rounded-full bg-primary px-28 py-5 text-3xl font-bold text-white hover:opacity-80"
        type="button"
      >
        Aplikuj
      </Link>
    </div>
  </div>
);
