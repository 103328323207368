import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MdClose, MdMenu } from 'react-icons/md';

import { navigationData } from '../utils';

import { HeaderNavigationElement } from './HeaderNavigationElement';

interface HeaderNavigationElementsInterface {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const HeaderNavigationElements = ({
  isOpen,
  setIsOpen,
}: HeaderNavigationElementsInterface) => (
  <>
    {navigationData.map((element) => (
      <HeaderNavigationElement
        {...element}
        key={element.name}
        isMobileNavigation={isOpen}
        onClick={() => setIsOpen(false)}
      />
    ))}
  </>
);

export const HeaderNavigation = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <>
      <nav className="hidden justify-center md:flex">
        <ul className="flex whitespace-nowrap text-xl font-semibold xs:gap-6 lg:gap-12 xl:gap-24 xl:text-2xl">
          <HeaderNavigationElements isOpen={isOpen} setIsOpen={setIsOpen} />
        </ul>
      </nav>
      <button
        className={`${
          isOpen ? '' : 'md:hidden'
        } relative z-50 text-5xl text-primary hover:opacity-80`}
        type="button"
        onClick={() => {
          setIsOpen((prevIsOpen) => {
            window.scrollTo(0, 0);
            return !prevIsOpen;
          });
        }}
        aria-expanded={isOpen}
        aria-controls="menu"
        tabIndex={0}
      >
        {isOpen ? <MdClose /> : <MdMenu />}
      </button>
      {isOpen ? (
        <nav
          id="menu"
          className="fixed left-0 top-0 z-40 min-h-[100vh] w-full bg-white pt-[160px]"
        >
          <ul className="flex flex-col gap-10 text-center font-semibold">
            <HeaderNavigationElements isOpen={isOpen} setIsOpen={setIsOpen} />
          </ul>
        </nav>
      ) : null}
    </>
  );
};
