export const workOfferDescription: string[] = [
  'Przyjmowanie i rozpatrywanie zwrotów',
  'Wydruk etykiet do znakowania produktów oraz w razie potrzeby znakowanie produktów do wysyłki',
  'Kontrola jakości przyjmowanych produktów',
  'Nadzór nad magazynem podręcznym materiałów eksploatacyjnych',
  'Utrzymanie porządku w miejscu wykonywania pracy',
  'Usuwanie znakowania produktów zwracanych',
];

export const workOfferRequirements: string[] = [
  'Podstawowa znajomość obsługi programów z Excel / Google Arkusze lub Word / Google Dokumenty',
  'Zaangażowanie i pozytywne nastawienie',
  'Znajomość języka angielskiego na poziomie średniozaawansowanym',
  'Chęć do nauki i rozwoju',
];

export const workOfferDetails: string[] = [
  'Pracę w pełnym wymiarze godzin',
  'Możliwość rozwoju oraz poszerzania kompetencji w szybko rozwijającej się firmie',
  'Przyjazną atmosferę pracy w zgranym zespole',
  'Konkurencyjne wynagrodzenie',
  'Dopłaty do dojazdów oraz karty Multisport',
];
