import { useEffect, useState } from 'react';

import { BREAKPOINTS } from 'utils';

import { OurOfferGridButton } from './OurOfferGridButton';
import { ourOfferGridData } from './ourOfferGridData';
import { OurOfferGridElement } from './OurOfferGridElement';

export const OurOfferGrid = () => {
  const [displayedId, setDisplayedId] = useState(0);
  const [isTransition, setIsTransition] = useState(false);
  const [elementWidth, setElementWidth] = useState(window.innerWidth);

  useEffect(() => {
    const getElementWidth = () => {
      setElementWidth(window.innerWidth);
    };

    window.addEventListener('resize', getElementWidth);

    return () => window.removeEventListener('resize', getElementWidth);
  }, []);

  useEffect(() => {
    const setIsTransitionTimeout = setTimeout(() => {
      setIsTransition(false);
    }, 300);

    return () => clearTimeout(setIsTransitionTimeout);
  }, [displayedId]);

  return (
    <div className="relative flex md:justify-center">
      <OurOfferGridButton
        disabled={displayedId === 0}
        onClick={() => {
          setIsTransition(true);
          setDisplayedId((prevDisplayedId) => prevDisplayedId - 1);
        }}
        isLeft
      />

      <div
        style={{
          width:
            window.innerWidth >= BREAKPOINTS.md ? 'auto' : window.innerWidth,
        }}
        className="flex h-auto w-full overflow-hidden md:w-auto md:max-w-[1400px]"
      >
        <div
          style={{
            transform:
              window.innerWidth >= BREAKPOINTS.md
                ? ''
                : `translateX(-${displayedId * elementWidth}px)`,
            transitionDuration: isTransition ? '300ms' : '0s',
          }}
          className="flex gap-y-24 transition-transform md:static md:left-0 md:flex-wrap md:justify-center md:gap-x-12"
        >
          {ourOfferGridData.map((element) => (
            <OurOfferGridElement {...element} key={element.title} />
          ))}
        </div>
      </div>

      <OurOfferGridButton
        disabled={displayedId === ourOfferGridData.length - 1}
        onClick={() => {
          setIsTransition(true);
          setDisplayedId((prevDisplayedId) => prevDisplayedId + 1);
        }}
      />
    </div>
  );
};
