import { variants } from '../../utils';

import { WorkOfferListInterface } from './WorkOfferList.interface';
import { WorkOfferListElement } from './WorkOfferListElement';

export const WorkOfferList = ({
  title,
  workOfferListData,
  elementTextColor = 'black',
}: WorkOfferListInterface) => (
  <div className="px-5 text-2xl sm:px-24 lg:pr-0">
    <h2 className="ml-7 font-semibold">{title}</h2>
    <ul className={`mt-6 ${variants[elementTextColor]}`}>
      {workOfferListData.map((element) => (
        <WorkOfferListElement element={element} key={element} />
      ))}
    </ul>
  </div>
);
